<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { levels } from "@/config/api/levels";

export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "PLAN LEVELS",
            items: [
                {
                    text: "All",
                },
                {
                    text: "LEVELS",
                    active: true,
                },
            ],
            levelList: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 100,
            filter: null,
            filterOn: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: false, label: "Name", thStyle: { width: "30%" } },
                { key: "views_profile", sortable: false, label: "Profile Views" },
                { key: "image_limit", sortable: false, label: "Image Limit" },
                { key: "display_banner", sortable: false },
                { key: "user_type", sortable: false },
                { key: "active", sortable: false },
                { key: "actions", sortable: false },
            ],
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.totalRows;
        },
    },
    mounted() {
        // Set the initial number of items
        this.loadData();
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered() {
            // Trigger pagination to update the number of buttons/pages due to filtering
            // this.totalRows = filteredItems.length;
            // this.currentPage = 1;
        },

        loadData(search = "") {
            const api = levels.getAll;
            api.params = { search: search, limit: 100 };
            this.generateAPI(api).then((res) => {
                this.levelList = res.data.plans;
            });
        },
        deleteItem(id) {
            let api = { ...levels.delete };
            api.url += id;
            this.generateAPI(api)
                .then((res) => {
                    this.loadData();
                    this.$bvToast.toast("Plan Level has been deleted!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((err) => {
                    this.$bvToast.toast("Could not delete Plan Level!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.log(err);
                });
        },



    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <b-button id="addProperty" @click="
                          $router.push({
                            path: `edit-levels`,
                          })
                        " pill variant="primary">Add Level</b-button>

                        <b-tabs nav-class="nav-tabs-custom">
                            <b-tab title-link-class="p-3">
                                <template v-slot:title>
                                    <a class="font-weight-bold active">All Plan Levels</a>
                                </template>
                                <!-- Search -->
                                <div style="margin-top:10px;margin-bottom: 10px;">

                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search"
                                                class="form-control form-control-sm ml-2">
                                            </b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->

                                <div class="table-responsive">
                                    <b-table class="table-centered" :items="levelList" :fields="fields" responsive="sm"
                                        :busy="$store.state.api.loading" :current-page="currentPage"
                                        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                        :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                                        <template #table-busy>
                                            <div style="text-align: center" class="mt-5 mb-5">
                                                <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                                            </div>
                                        </template>

                                        <template v-slot:cell(name)="row">
                                            {{ row.value }}
                                        </template>
                                        <template v-slot:cell(views_profile)="row">
                                            {{ row.item.features.views_profile }}
                                        </template>
                                        <template v-slot:cell(image_limit)="row">
                                            {{ row.item.features.image_limit }}
                                        </template>
                                        <template v-slot:cell(display_banner)="row">
                                            {{ row.item.features.display_banner }}
                                        </template>


                                        <template #cell(active)="row">
                                            {{ row.item.deletedAt ? "Deleted" : "Active" }}
                                        </template>
                                        <template #cell(actions)="row">
                                            <button class="action-btn" @click="deleteItem(row.item._id)">
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                            <button class="action-btn" @click="
                                              $router.push({
                                                path: `edit-levels`,
                                                query: { id: row.item._id },
                                              })
                                            ">
                                                <i class="ri-edit-line"></i>
                                            </button>
                                        </template>
                                    </b-table>
                                </div>
                                <!-- <div class="row">
                                    <div class="col">
                                        <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                                            <ul class="pagination pagination-rounded mb-0">
                                              
                                                <b-pagination v-model="currentPage" :total-rows="rows"
                                                    :per-page="perPage" @change="updatePage">
                                                </b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
#addProperty {
    float: right;
    margin-top: 10px;
}
</style>


